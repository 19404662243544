import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  padding: 0.1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-align: left;
  font-family: 'Baloo Tamma 2', cursive;
  color: whitesmoke;
  background: #1f4068;
  box-shadow: 0.2em 0.2em 0.2em black;
  border-radius: 0.2em;
  border-color: white;
  border-width: thin;
  height: 3em;
  ${(props) => props.isEmpty && `width: 5em;`}
  ${(props) => {
    if (props.position === 'TOP') {
      return `
        left: 50%;
        transform: translate(-50%, 0);`;
    }
    if (props.position === 'LEFT') {
      return `
        top: 50%;
        transform: translate(5%, -50%);`;
    }
    if (props.position === 'BOTTOM') {
      return `
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);`;
    }
    if (props.position === 'RIGHT') {
      return `
        top: 50%;
        right: 0;
        transform: translate(-5%, -50%);`;
    }
    return ``;
  }};
`;

// const Icon = styled.div`
//   float: left;
//   height: 100%;
//   padding-right: 0.25em;
//   font-size: 2em;
// `;

const NameScoreWrapper = styled.div`
  float: left;
  font-size: 1.25em;
  line-height: 1em;
  margin-top: 0.25em;
`;

const Score = styled.div`
  font-size: 0.5em;
  line-height: 1em;
  text-align: center;
`;

function Player(props) {
  const { data, position } = props;
  let content = <></>;

  if (data !== null) {
    content = (
      <>
        {/* <Icon>{data.emoji}</Icon> */}
        <NameScoreWrapper>
          <div>{data.name}</div>
          <Score>
            {data.roundScore}
            {' | '}
            {data.totalScore}
          </Score>
        </NameScoreWrapper>
      </>
    );
  }

  return (
    <Container position={position} isEmpty={data === null}>
      {content}
    </Container>
  );
}

Player.propTypes = {
  data: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
};

export default Player;
