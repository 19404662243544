const socketEvents = {
  RELOAD_CONNECTION: 'RELOAD_CONNECTION',
  DEALT_CARDS: 'DEALT_CARDS',
  PLAYED_CARD: 'PLAYED_CARD',
  TRICK_COMPLETE: 'TRICK_COMPLETE',
  CREATE_GAME: 'CREATE_GAME',
  JOIN_GAME: 'JOIN_GAME',
  GAME_JOINED: 'GAME_JOINED',
  REQUEST_PLAYER_DATA: 'REQUEST_PLAYER_DATA',
  PLAYER_DATA_UPDATE: 'PLAYER_DATA_UPDATE',
  JOIN_FAILED: 'JOIN_FAILED',
  PASS_CARDS: 'PASS_CARDS',
  PASSED_CARDS: 'PASSED_CARDS',
  CONTINUE_GAME: 'CONTINUE_GAME',
};

export default socketEvents;
