import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Game from './game';
import LandingPage from './landingPage';
import socketEvents from '../socketEvents';

const Background = styled.div`
  text-align: center;
`;

class MainWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gameInfo: null,
    };
  }

  componentDidMount() {
    const { socket } = this.props;

    socket.on(socketEvents.GAME_JOINED, (payload) => {
      setTimeout(() => {
        this.setState({
          gameInfo: { code: payload.gameCode, position: payload.position },
        });
      }, payload.position > 0 && 500);
    });
  }

  render() {
    const { socket } = this.props;
    const { gameInfo } = this.state;

    return (
      <Background>
        {gameInfo !== null && (
          <Game socket={socket} gameCode={gameInfo.code} position={gameInfo.position} />
        )}
        {gameInfo === null && <LandingPage socket={socket} />}
      </Background>
    );
  }
}

MainWrapper.propTypes = {
  socket: PropTypes.object.isRequired,
};

export default MainWrapper;
