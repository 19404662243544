import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import BoardCenter from './boardCenter';
import Player from './player';

const BoardContainer = styled.div`
  position: relative;
  height: 300px;
  width: 700px;
`;

const Oval = styled.div`
  width: 90%;
  height: 90%;
  background: #107543;
  border-radius: 50%;
  border-style: solid;
  border-color: black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const PassContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Baloo Tamma 2', cursive;
  height: 2em;
  width: 5em;
  color: whitesmoke;
  font-size: 2em;
  padding: 0.1em;
  padding-left: 0.2em;
  padding-right: 0.2em;
`;

const GameCodeContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Baloo Tamma 2', cursive;
  height: 2em;
  width: 5em;
  color: whitesmoke;
  font-size: 2em;
  background: #24ab68;
  border-radius: 0.2em;
  border-color: white;
  border-width: thin;
  padding: 0.1em;
  padding-left: 0.2em;
  padding-right: 0.2em;
`;

const Code = styled.div`
  line-height: 1em;
  &:hover {
    color: grey;
    text-shadow: 0.1em 0.1em 0.1em black;
  }
`;

const Arrows = styled.div`
    position: absolute;
    left: 50%;
    bottom: 60px;
    transform: translate(-50%, 0);
    height: 10px; width: 10px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-width: 3px 3px 0 0;
    transform: rotate(${(props) => {
      if (props.passDirection) {
        switch(props.passDirection) {
          case "LEFT":
            return '-135deg';
          case "RIGHT":
            return '45deg';
          case "ACROSS":
            return '-45deg';
          default:
            return '135deg';
        }
      }
      return '135deg';
    }});

  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    height: 10px; width: 10px;
    border-width: 3px 3px 0 0;
  }

  &:before {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-width: 3px 3px 0 0;
    /* top - distance minus border */
    top: 7px; left: -10px;
  }

  &:after {
    border: 1px solid rgba(255, 255, 255, 1);
    border-width: 3px 3px 0 0;
    /* top - distance plus border */
    top: -13px; left: 10px;
  }
`;

class Board extends Component {
  constructor(props) {
    super(props);
    this.copyCodeToClip = this.copyCodeToClip.bind(this);
  }

  copyCodeToClip() {
    // FIXME
    try {
      const { gameCode } = this.props;
      // eslint-disable-next-line no-undef
      navigator.clipboard.writeText(gameCode);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('clipboard not accessible');
    }
  }

  render() {
    const { cards, playerData, position, gameCode, passCards, handlePassCardsClicked, passDirection } = this.props;

    return (
      <BoardContainer>
        <Oval />
        <Player data={playerData[position]} position="BOTTOM" />
        <Player data={playerData[(position + 1) % 4]} position="LEFT" />
        <Player data={playerData[(position + 2) % 4]} position="TOP" />
        <Player data={playerData[(position + 3) % 4]} position="RIGHT" />
        <BoardCenter cards={cards} />
        {gameCode !== null && (
          <GameCodeContainer>
            <div style={{ fontSize: '.5em' }}>GAME CODE</div>
            <Code onClick={this.copyCodeToClip}>{gameCode}</Code>
          </GameCodeContainer>
        )}
        {gameCode === null && passCards && (
          <PassContainer>
            <Button variant="primary" onClick={() => handlePassCardsClicked()}>
              Pass Cards
            </Button>
          </PassContainer>
        )}
        {passDirection !== null && (
          <Arrows passDirection={passDirection} />
        )}
      </BoardContainer>
    );
  }
}

Board.propTypes = {
  cards: PropTypes.string.isRequired,
  playerData: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
  gameCode: PropTypes.string,
};

Board.defaultProps = {
  gameCode: null,
};

export default Board;
