/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import './App.css';
import io from 'socket.io-client';
import MainWrapper from './components/mainWrapper';

function App() {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    fetch('/api/connect')
      .then(() => {})
      .then(() => setSocket(io({
        path: '/api'
      })));
  }, []);

  return <>{socket !== null && <MainWrapper socket={socket} />}</>;
}

export default App;
