import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ButtonGroup, Form, Row, Col } from 'react-bootstrap';
import socketEvents from '../socketEvents';

const Title = styled.div`
  font-family: 'Baloo Tamma 2', cursive;
  font-size: 5em;
  color: whitesmoke;
  padding-top: 0.5em;
`;

const Container = styled.div`
  display: inline-block;
  width: 27em;
  padding: 2em;
  @media only screen and (max-width: 27em) {
    width: 90%;
  }
`;

const JOIN = 'JOIN';
const CREATE = 'CREATE';

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.onToggleJoin = this.onToggleJoin.bind(this);
    this.onToggleCreate = this.onToggleCreate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.tooltipTarget = React.createRef();
    this.state = { formToggled: CREATE };
  }

  componentDidMount() {
    const { socket } = this.props;

    socket.on(socketEvents.JOIN_FAILED, (payload) => {
      this.setState({ gameCodeIsValid: false, nameIsValid: true });
    });

    socket.on(socketEvents.GAME_JOINED, (payload) => {
      this.setState({ gameCodeIsValid: true, nameIsValid: true });
    });
  }

  onToggleJoin() {
    this.setState({ formToggled: JOIN });
  }

  onToggleCreate() {
    this.setState({ formToggled: CREATE });
  }

  handleSubmit() {
    const { socket } = this.props;
    const { formToggled } = this.state;
    const playerName = document.getElementById('playerName').value;

    if (formToggled === JOIN) {
      const gameCode = document.getElementById('gameCode').value;

      socket.emit(socketEvents.JOIN_GAME, { name: playerName, gameCode });
    } else {
      const deckCount = document.getElementById('deckCount').value;
      const passMode = document.getElementById('passMode').value;
      socket.emit(socketEvents.CREATE_GAME, { name: playerName, deckCount: deckCount, passMode: passMode });
    }
  }

  render() {
    const titleText = (
      <span>
        HE
        <span style={{ fontSize: '.75em' }}>♡</span>
        RTS
      </span>
    );

    const { formToggled, nameIsValid, gameCodeIsValid } = this.state;

    return (
      <>
        <Title>{titleText}</Title>
        <Container>
          <ButtonGroup aria-label="Basic example" style={{ paddingBottom: '1em', width: '100%' }}>
            <Button
              variant="outline-light"
              style={{ boxShadow: 'none' }}
              className={formToggled === JOIN && 'active'}
              onClick={this.onToggleJoin}
            >
              Join Game
            </Button>
            <Button
              variant="outline-light"
              style={{ boxShadow: 'none' }}
              className={formToggled === CREATE && 'active'}
              onClick={this.onToggleCreate}
            >
              Create Game
            </Button>
          </ButtonGroup>
          <Form>
            {formToggled === JOIN && (
              <Form.Group>
                <Form.Control
                  id="gameCode"
                  size="lg"
                  type="text"
                  placeholder="Enter game code"
                  style={{ textAlign: 'center' }}
                  isValid={typeof gameCodeIsValid !== 'undefined' && gameCodeIsValid}
                  isInvalid={typeof gameCodeIsValid !== 'undefined' && !gameCodeIsValid}
                />
              </Form.Group>
            )}
            <Form.Group>
              <Form.Control
                id="playerName"
                size="lg"
                type="text"
                placeholder="Enter player name"
                style={{ textAlign: 'center' }}
                isValid={typeof nameIsValid !== 'undefined' && nameIsValid}
                isInvalid={typeof nameIsValid !== 'undefined' && !nameIsValid}
              />
            </Form.Group>
            {formToggled === CREATE && (
              <Form.Group as={Row} controlId="formHorizontalDeckCount">
                <Form.Label style={{color: 'white', margin: 'auto', fontSize: '20px'}} column sm={6}>Number of decks:</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    id="deckCount"
                    size="lg"
                    as="select"
                    style={{ textAlign: 'center' }}>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            )}
            {formToggled === CREATE && (
              <Form.Group as={Row} controlId="formHorizontalPassMode">
                <Form.Label style={{color: 'white', margin: 'auto', fontSize: '20px'}} column sm={4}>Passing:</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    id="passMode"
                    size="lg"
                    as="select"
                    style={{ textAlign: 'center' }}>
                      <option value="NORMAL">Normal</option>
                      <option value="TRIPLE">Triple Pass</option>
                      <option value="MIDDLE">Cards in the middle</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            )}
            <Button variant="primary" style={{ width: '100%' }} onClick={this.handleSubmit}>
              {formToggled === JOIN ? 'Join Game' : 'Create Game'}
            </Button>
          </Form>
        </Container>
      </>
    );
  }
}

LandingPage.propTypes = {
  socket: PropTypes.object.isRequired,
};

export default LandingPage;
