import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

const CardBody = styled.div`
  position: ${(props) => {
    if (props.rotation) {
      return 'absolute';
    }
    return 'relative';
  }};
  box-shadow: ${(props) => {
    if (props.selected) {
      return '0 0 8px #fff81b';
    }
    return '0 0 0px';
  }};
  height: 3.5em;
  width: 2.5em;
  color: black;
  background: ${(props) => {
    if (props.selected) {
      return '#fffc9a';
    }
    return 'white';
  }};
  font-family: 'DM Serif Display', serif;
  ${(props) =>
    !props.rotation &&
    `
    margin: .1em;`};
  ${(props) =>
    props.rotation &&
    `
    left: 50%;
    top 50%;
  `};
  transform: ${(props) => {
    if (props.rotation === 'TOP') {
      return 'translate(-50%, 0%) rotate(0) translate(0, -125%)';
    }
    if (props.rotation === 'LEFT') {
      return 'translate(-50%, 0%) rotate(90deg) translate(0, 25%)';
    }
    if (props.rotation === 'RIGHT') {
      return 'translate(-50%, 0%) rotate(270deg) translate(0, 25%)';
    }
    // BOTTOM
    return 'translate(-50%, 0%) rotate(0) translate(0, 25%)';
  }};
  transform-origin: top center;
  border-radius: .2em;
  border-style: solid;
  border-color: grey;
  border-width: thin;
  cursor: pointer;
`;

const Value = styled.div`
  position: absolute;
  font-size: 1.5em;
  margin-left: 0.25em;
  color: ${(props) => props.color};
`;

const Suit = styled.div`
  position: absolute;
  font-size: ${(props) => (props.isFirefox ? '1.5em' : '1.25em')};
  bottom: 0;
  right: 0;
  margin-right: 0.1em;
  margin-bottom: 0.1em;
  color: ${(props) => props.color};
`;

const unicodeSuits = { Clubs: '♣', Diamonds: '♦', Hearts: '♥', Spades: '♠' };
const faceOf = { 11: 'J', 12: 'Q', 13: 'K', 14: 'A' };

class Card extends Component {
  getKey() {
    const { value, suit } = this.props;
    return value + suit;
  }

  render() {
    const { value, suit, deck, rotation, handleCardClick, selected } = this.props;
    const suitIcon = typeof unicodeSuits[suit] === 'undefined' ? 'X' : unicodeSuits[suit];
    const color = ['Diamonds', 'Hearts'].includes(suit) ? 'red' : 'black';

    return (
      <CardBody
        rotation={rotation}
        onClick={() => handleCardClick(suit, value, deck)}
        selected={selected}
      >
        <Value color={color}>{value > 10 ? faceOf[value] : value}</Value>
        <Suit color={color} isFirefox={isFirefox}>
          {suitIcon}
        </Suit>
      </CardBody>
    );
  }
}

Card.propTypes = {
  value: PropTypes.number.isRequired,
  suit: PropTypes.string.isRequired,
  rotation: PropTypes.number.isRequired,
  handleCardClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default Card;
