import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Card from './card';

const HandContainer = styled.div`
  display: table;
  margin: 0 auto;
  padding-top: 2em;
`;

// const HandContainer = styled.div`
//   display: flex;
//   margin: 0 auto;
//   padding-top: 2em;
//   overflow-x: scroll;
//   &::-webkit-scrollbar {
//     width: 10px;
//     height: 10px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background: #201c29;
//     border-radius: 10px;
//     box-shadow: inset 2px 2px 2px hsla(0,0%,100%,.25), inset -2px -2px 2px rgba(0,0,0,.25);
//   }
//   &::-webkit-scrollbar-track {
//     background: linear-gradient(90deg,#201c29,#201c29 1px,#17141d 0,#17141d);
//   }
// `;

const CardContainer = styled.div`
  display: inline-block;
  transition: .2s;

  &:hover {
    transform: translateY(-1rem);
  }
`;

// const CardContainer = styled.div`
//   height: 350px;
//   width: 400px;
//   min-width: 250px;
//   padding: 1.5rem;
//   border-radius: 16px;
//   background: #17141d;
//   box-shadow: -1rem 0 3rem #000;
//   display: flex;
//   flex-direction: column;
//   transition: .2s;
//   margin: 0;
//   scroll-snap-align: start;
//   clear: both;
//   position: relative;

//   &:focus-within~.CardContainer, &:hover~.CardContainer {
//     transform: translateX(130px);
//   }

//   &:hover {
//     transform: translateY(-1rem);
//   }

//   &:not(:first-child) {
//     margin-left: -130px;
//   }
// `;

function Hand(props) {
  const { cards } = props;

  return (
    <HandContainer>
      {cards.map((card) => (
        <CardContainer key={card.props.deck + card.props.suit + card.props.value}>{card}</CardContainer>
      ))}
    </HandContainer>
  );
}

Hand.propTypes = {
  cards: PropTypes.arrayOf(Card).isRequired,
};

export default Hand;
