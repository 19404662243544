import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Hand from './hand';
import Card from './card';
import Board from './board';
import socketEvents from '../socketEvents';

const GAME_HEIGHT = 510;
const GAME_WIDTH = 700;

const GameWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  background: #162447;
  display: inline-block;
  ${(props) =>
    props.scale &&
    `
    transform: translate(-50%, -50%) scale(${props.scale})
  `};
`;

class Game extends Component {
  static mod(n, m) {
    return ((n % m) + m) % m;
  }

  static generateCards(cardData, clickHandler) {
    return cardData.map((data) => (
      <Card suit={data.suit} value={data.value} handleCardClick={clickHandler} selected={data.selected} deck={data.deck} />
    ));
  }

  static canPassCards(stage, passMode, handCardData, passCardData) {
    if (stage === "PASS") {
      if (passMode === "MIDDLE") {
        return true;
      }
      return passCardData.length === Math.round(handCardData.length / 4.33);
    }
    return false;
  }

  constructor(props) {
    super(props);
    this.state = {
      scale: 100,
      height: GAME_HEIGHT,
      width: GAME_WIDTH,
      boardCards: [<></>, <></>, <></>, <></>],
      handCardData: [],
      turn: null,
      validSuits: null,
      playerData: null,
      showGameCode: true,
      stage: "WAIT",
      passMode: null,
      passDirection: null,
      passCardData: [],
    };
  }

  componentDidMount() {
    const { socket } = this.props;

    // SOCKET LISTENERS

    socket.emit(socketEvents.REQUEST_PLAYER_DATA);

    socket.on(socketEvents.PLAYER_DATA_UPDATE, (payload) => {
      this.setState({ playerData: payload.playerData });
    });

    socket.on(socketEvents.RELOAD_CONNECTION, () => window.location.reload(true));

    // socket.on(socketEvents.CONTINUE_GAME, (payload) => {
    //   this.setState({
    //     boardCards,
    //     handCardData: payload.handCardData,
    //     turn: payload.turn,
    //     validSuits: payload.validSuits,
    //     playerData: payload.playerData,
    //     stage: payload.stage,
    //     passDirection: payload.passDirection,
    //     passCardData: payload.passCardData,
    //     showGameCode: false,
    //   })
    // });

    socket.on(socketEvents.DEALT_CARDS, (payload) => {
      this.setState({
        handCardData: payload.hand,
        turn: payload.turn,
        validSuits: payload.validSuits,
        showGameCode: false,
        stage: payload.stage,
        passMode: payload.passMode,
        passDirection: payload.passDirection,
      });
    });

    socket.on(socketEvents.PASSED_CARDS, (payload) => {
      this.setState({
        handCardData: payload.hand,
        turn: payload.turn,
        validSuits: payload.validSuits,
        showGameCode: false,
        stage: payload.stage,
        passMode: payload.passMode,
        passDirection: payload.passDirection,
      });
    });

    socket.on(socketEvents.PLAYED_CARD, (payload) => {
      const { boardCards } = this.state;
      const { position } = this.props;
      const relativePos = Game.mod(payload.position - position, 4);
      const rotation = ['BOTTOM', 'LEFT', 'TOP', 'RIGHT'];

      boardCards[relativePos] = (
        <Card
          suit={payload.card.suit}
          value={payload.card.value}
          rotation={rotation[relativePos]}
          handleCardClick={() => {}}
        />
      );
      this.setState({
        boardCards,
        turn: payload.turn,
        validSuits: payload.validSuits,
        playerData: payload.playerData,
      });
    });

    socket.on(socketEvents.TRICK_COMPLETE, (payload) => {
      const boardCards = [<></>, <></>, <></>, <></>];

      this.setState({
        boardCards,
        turn: payload.turn,
        validSuits: payload.validSuits,
        playerData: payload.playerData,
      });
    });

    this.resize();
    window.addEventListener('resize', this.resize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  handleCardInHandClick(suit, value, deck) {
    const { boardCards, handCardData, turn, validSuits, stage, passCardData, passMode } = this.state;
    const { position, socket } = this.props;

    if (stage === "PASS") {
      const index = handCardData.findIndex((card) => card.suit === suit && card.value === value && card.deck === deck);
      if (handCardData[index].selected) {
        handCardData[index].selected = false;
        const index2 = passCardData.indexOf(index);
        passCardData.splice(index2, 1);
      } else if (passMode === "MIDDLE" || passCardData.length < Math.round(handCardData.length / 4.33)) {
        handCardData[index].selected = true;
        passCardData.push(index)
      } else {
        return;
      }
      this.setState({ handCardData, passCardData });
      return;
    }

    if (stage !== "PLAY") {
      return;
    }

    // not clients turn OR client clicked an invalid suit while their hand contained a valid suit
    if (
      turn !== position ||
      (!validSuits.includes(suit) &&
        validSuits.some((validSuit) => handCardData.some((card) => validSuit === card.suit)))
    ) {
      return;
    }

    const index = handCardData.findIndex((card) => card.suit === suit && card.value === value && card.deck === deck);
    const card = handCardData.splice(index, 1)[0];

    boardCards[0] = <Card suit={suit} value={value} rotation="BOTTOM" />;
    socket.emit(socketEvents.PLAYED_CARD, {
      position,
      card,
      validSuits,
    });
    this.setState({ boardCards, handCardData });
  }

  handlePassCardsClicked() {
    const { handCardData, passCardData } = this.state;
    const { position, socket } = this.props;
    const passIndices = passCardData.sort((a, b) => b-a);
    let passedCards = []
    for (let i = 0; i < passIndices.length; i++) {
      handCardData[passIndices[i]].selected = false;
      let card = handCardData.splice(passIndices[i], 1)[0];
      passedCards.push(card);
    }
    socket.emit(socketEvents.PASS_CARDS, {
      position,
      passedCards,
    });
    this.setState({ handCardData, passCardData: [], stage: "WAIT_FOR_PASS" });
  }

  resize() {
    const { width, height } = this.state;
    const scale = Math.min(window.innerWidth / width, window.innerHeight / height);

    this.setState({ scale });
  }

  render() {
    const { scale, boardCards, playerData, showGameCode, handCardData, passCardData, stage, passDirection, passMode } = this.state;
    const { socket, position, gameCode } = this.props;
    const passCards = Game.canPassCards(stage, passMode, handCardData, passCardData);
    return (
      <GameWrapper scale={scale}>
        {socket !== null && playerData !== null && (
          <>
            <Board
              cards={boardCards}
              playerData={playerData}
              position={position}
              gameCode={showGameCode ? gameCode : null}
              passCards={passCards}
              passDirection={stage === "PASS" ? passDirection : null}
              handlePassCardsClicked={this.handlePassCardsClicked.bind(this)}
            />
            ``
            <Hand cards={Game.generateCards(handCardData, this.handleCardInHandClick.bind(this))} />
          </>
        )}
      </GameWrapper>
    );
  }
}

Game.propTypes = {
  gameCode: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  socket: PropTypes.object,
};

Game.defaultProps = {
  socket: null,
};

export default Game;
